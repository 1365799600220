import React from 'react';
import PropTypes from 'prop-types';
// pieces imports
import { Box } from 'pieces';

export const Line = ({ sx }) => (
  <Box sx={{ bg: 'primary', width: '3px', ...sx }} />
);

Line.propTypes = {
  sx: PropTypes.shape(),
};

Line.defaultProps = {
  sx: {},
};
