import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
// pieces import
import { Box, Card, Paragraph, Heading } from 'pieces';
// component import
import { CardButton } from 'components/buttons';

export const CategoryCard = ({
  sx,
  cardTitle,
  cardParagraph,
  imgSrc,
  buttonText,
  ...props
}) => (
  <>
    <Card
      variant='categoryCard'
      sx={{
        ...sx,
      }}
      {...props}
    >
      <GatsbyImage image={imgSrc} style={{ width: '100%' }} />
      <Box sx={{ pl: ['0', '0', '1.5em', '1.5em', '0', '0'] }}>
        <Box
          sx={{
            margin: [
              '1.5em 0 3em',
              '1.5em 0 3em',
              '0 0 3em',
              '0 0 3em',
              '1.5em 0 3em',
              '1.5em 0 3em',
            ],
          }}
        >
          <Heading as='h4' type='cardTitle'>
            {cardTitle}
          </Heading>
          <Paragraph type='cardParagraph' sx={{ mt: '0.25rem' }}>
            {cardParagraph}
          </Paragraph>
        </Box>
        <CardButton text={buttonText} />
      </Box>
    </Card>
  </>
);

CategoryCard.propTypes = {
  sx: PropTypes.shape(),
  cardTitle: PropTypes.string.isRequired,
  cardParagraph: PropTypes.string.isRequired,
  imgSrc: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  buttonText: PropTypes.string.isRequired,
};

CategoryCard.defaultProps = {
  sx: {},
};
