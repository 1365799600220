// external dependencies
import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// pieces components
import { Section, GridItem } from 'pieces';
// local components
import { QuoteBlock } from 'blocks';
import { Line } from 'components';

export const Quote = memo(() => {
  const {
    landingPageJson: {
      quote: { quote, author, position },
    },
  } = useStaticQuery(graphql`
    query {
      landingPageJson {
        quote {
          quote
          author
          position
        }
      }
    }
  `);

  return (
    <Section id='quote'>
      <GridItem
        sx={{ gridColumn: ['1/13', '1/13', '2/24', '4/21', '1/2', '6/7'] }}
      >
        <Line
          sx={{
            height: ['80px', '80px', '80px', '40px', '100%'],
            marginRight: 'auto',
            marginLeft: ['auto', 'auto', 'auto', 'auto', 0, 0],
            marginBottom: [24, 24, 40, 30, 0],
          }}
        />
      </GridItem>
      <GridItem
        sx={{
          gridColumn: ['1/13', '1/13', '2/24', '4/21', '2/18', '7/19'],
          marginTop: [0, 0, 0, 0, 74, 150],
        }}
      >
        <QuoteBlock quote={quote} author={author} position={position} />
      </GridItem>
    </Section>
  );
});
