// external dependencies
import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// pieces components
import {
  Section,
  Heading,
  GridItem,
  Paragraph,
  Box,
  Image,
  FlexWrapper,
  FormField,
  GridWrapper,
} from 'pieces';
// components imports
import { Line } from 'components';
import { SecondaryButton } from 'components/buttons';

export const Contact = memo(() => {
  const {
    landingPageJson: {
      contact: {
        headline,
        subheadline,
        text,
        forms: {
          buttonText,
          headline: formHeadline,
          label: {
            email: labelEmail,
            name: labelName,
            message: labelMessage,
            tel: labelTel,
          },
          placeholder: {
            email: placeholderEmail,
            message: placeholderMessage,
            name: placeholderName,
            tel: placeholderTel,
          },
        },
        data: { headline: dataHeadline, phone, address },
      },
    },
    buttonDecor: { publicURL: buttonDecorSrc },
  } = useStaticQuery(graphql`
    query {
      landingPageJson {
        contact {
          headline
          subheadline
          text
          forms {
            buttonText
            headline
            label {
              email
              name
              message
              tel
            }
            placeholder {
              email
              message
              name
              tel
            }
          }
          data {
            headline
            phone
            address
          }
        }
      }
      buttonDecor: file(relativePath: { eq: "decoration/3-stripes.svg" }) {
        publicURL
      }
    }
  `);

  const marginTopInput = [24, 24, 24, 28, 24, 28];

  return (
    <Section id='contact' sx={{ overflow: 'hidden' }}>
      {/* Line decoration */}
      <GridItem
        sx={{
          display: ['none', 'none', 'none', 'none', 'block', 'block'],
          gridColumnStart: [null, null, null, null, 4, 3],
          gridRowStart: [null, null, null, null, 1, 1],
          zIndex: -4,
          mb: 'auto',
        }}
      >
        <Line sx={{ height: [null, null, null, null, 170, 259] }} />
      </GridItem>
      {/* Text block start */}
      <GridItem
        sx={{
          gridColumn: [
            '1 / span 12',
            '1 / span 9',
            '1 / span 12',
            '1 / span 13',
            '12 / span 9',
            '13 / span 8',
          ],
          gridRowStart: [1, 1, 1, 1, 1, 1],
          mt: [0, 0, 0, 0, 24, 178],
          mb: [48, 48, 0, 0, 0, 0],
        }}
      >
        <Heading type='h4' as='h4'>
          {subheadline}
        </Heading>
        <Heading
          type='h3'
          as='h3'
          sx={{
            margin: [
              '8px 0 16px',
              '8px 0 16px',
              '8px 0 16px',
              '8px 0 16px',
              '8px 0 16px',
              '8px 0 24px',
            ],
          }}
        >
          {headline}
        </Heading>
        <Paragraph>{text}</Paragraph>
      </GridItem>
      {/* Text block end */}
      {/* Map placeholder */}
      <Box
        sx={{
          bg: '#EEEEEE',
          height: [240, 300, 300, 282, 412, 654],
          gridColumn: [
            '1 / span 12',
            '5 / span 8',
            '15 / span 10',
            '15 / span 10',
            '1 / span 10',
            '1 / span 11',
          ],
          gridRowStart: [2, 2, 2, 2, 2, 2],
          mt: [null, null, -132, -164, -156, -204],
          zIndex: -3,
        }}
      />
      {/* Contact form start */}
      <GridItem
        sx={{
          // must use include phone potrait if using array as value
          // pass it as null
          variant: 'measure.gridColumnFallback',
          p: '40px 28px',
          borderRadius: '24px 0px 0px 0px',
          boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.2)',
          gridColumn: [
            null,
            '1 / span 11',
            '3 / span 20',
            '2 / span 22',
            '10 / span 15',
            '13 / span 12',
          ],
          gridRowStart: 3,
          mt: [null, -35, -53, -38, -226, -337],
          bg: 'white',

          // apply styling for phone potrait in media query
          '@media screen and (max-width: 500px)': {
            variant: 'measure.fullWidth',
          },
        }}
      >
        {/* Form start */}
        <Box as='form'>
          <Heading type='h3' as='h3' sx={{ mb: [24, 40, 48, 32, 48, 48] }}>
            {formHeadline}
          </Heading>
          {/* Input form start */}
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(8, 1fr)',
              columnGap: '16px',
            }}
          >
            <FormField
              sx={{ gridColumn: ['1/4', '1/4', '1/3', '1/3', '1/3', '1/3'] }}
              inputVariant='input'
              name='salutation'
              label={labelName[0]}
              placeholder={placeholderName[0]}
              required
            />
            <FormField
              sx={{ gridColumn: ['4/9', '4/9', '3/6', '3/6', '3/6', '3/6'] }}
              inputVariant='input'
              name='firstName'
              label={labelName[1]}
              placeholder={placeholderName[1]}
              required
            />
            <FormField
              sx={{
                mt: [24, 24, 0, 0, 0, 0],
                gridColumn: ['1/9', '1/5', '6/9', '6/9', '6/9', '6/9'],
              }}
              inputVariant='input'
              name='surname'
              label={labelName[2]}
              placeholder={placeholderName[2]}
              required
            />
            <FormField
              sx={{
                mt: marginTopInput,
                gridColumn: ['1/9', '5/9', '1/4', '1/4', '1/4', '1/4'],
              }}
              inputVariant='input'
              name='telephone'
              label={labelTel}
              placeholder={placeholderTel}
              type='tel'
              required
            />
            <FormField
              sx={{
                mt: marginTopInput,
                gridColumn: ['1/9', '1/9', '4/9', '4/9', '4/9', '4/9'],
              }}
              inputVariant='input'
              name='email'
              label={labelEmail}
              placeholder={placeholderEmail}
              type='email'
              required
            />
            <FormField
              sx={{
                mt: marginTopInput,
                gridColumn: ['1/9', '1/9', '1/9', '1/9', '1/9', '1/9'],
              }}
              inputVariant='input'
              inputAs='textarea'
              name='message'
              label={labelMessage}
              placeholder={placeholderMessage}
              rows={4}
              required
            />
          </Box>
          {/* Input form end */}
          <FlexWrapper
            sx={{ alignItems: 'center', mt: [24, 40, 48, 32, 48, 48] }}
          >
            <SecondaryButton text={buttonText} />
            <Image
              src={buttonDecorSrc}
              sx={{ height: [32, 32, 43, 43, 43, 43], ml: 24 }}
            />
          </FlexWrapper>
        </Box>
        {/* Form end */}
      </GridItem>
      {/* Contact form end */}
      {/* Contact number */}
      <GridItem
        sx={{
          variant: 'measure.fullWidth',
          bg: 'gray100',
          mt: [0, 80, 160, 108, 160, 107],
          py: ['48px', '38px', '40px', '42px', '74px', '71px'],
          zIndex: -2,
        }}
      >
        <GridWrapper>
          <Heading
            type='contactDataHeadline'
            as='h4'
            sx={{
              gridColumn: [
                '1/span 12',
                '1/span 10',
                '1/span 22',
                '1/span 22',
                '1/span 10',
                '1/span 10',
              ],
            }}
          >
            {dataHeadline}
          </Heading>
          <Heading
            type='h3Emphasized'
            as='h3'
            sx={{
              color: 'primary',
              mb: 8,
              gridColumn: [
                '1/span 12',
                '1/span 10',
                '1/span 22',
                '1/span 22',
                '1/span 10',
                '1/span 10',
              ],
            }}
          >
            {phone}
          </Heading>
          <Paragraph
            sx={{
              gridColumn: [
                '1/span 12',
                '1/span 12',
                '1/span 16',
                '1/span 17',
                '1/span 12',
                '1/span 8',
              ],
            }}
          >
            {address}
          </Paragraph>
        </GridWrapper>
      </GridItem>
      {/* Contact number end */}
    </Section>
  );
});
