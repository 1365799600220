// external dependencies
import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// pieces components
import { Section, GridItem, Image, Heading } from 'pieces';
import { QuoteBlock } from 'blocks';
import { Line } from 'components';
// components import
import { MediaCard } from 'components/cards';

export const Team = memo(() => {
  const {
    landingPageJson: {
      team: {
        quote,
        author,
        position,
        headline,
        cardTitle,
        cardText,
        cardImage,
        buttonText,
      },
    },
    stripes: { publicURL: stripeSrc },
    circle: { publicURL: circleSrc },
  } = useStaticQuery(graphql`
    {
      landingPageJson {
        team {
          quote
          author
          position
          headline
          cardTitle
          cardText
          cardImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
          buttonText
        }
      }
      stripes: file(relativePath: { eq: "decoration/stripes.svg" }) {
        publicURL
      }
      circle: file(relativePath: { eq: "decoration/full-red-circle.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <Section
      id='team'
      sx={{
        overflow: [
          'hidden',
          'visible',
          'visible',
          'visible',
          'visible',
          'visible',
        ],
      }}
    >
      {/* Line decoration */}
      <GridItem
        sx={{
          gridColumnStart: [1, 1, 4, 1, 5, 21],
          mb: [null, null, null, null, null, -132],
          mx: ['auto', 'auto', 0, 'auto', 0, 0],
        }}
      >
        <Line
          sx={{
            height: [80, 64, 3, 40, 3, 480],
            width: [3, 3, 384, 3, 370, 3],
          }}
        />
      </GridItem>
      {/* Quote Block */}
      <GridItem
        sx={{
          my: [24, 40, 40, 40, 48, 0],
          gridColumn: [
            '1 / span 12',
            '1 / span 12',
            '6 / span 16',
            '3 / span 20',
            '8 / span 15',
            '8 / span 13',
          ],
        }}
      >
        <QuoteBlock
          quote={quote}
          author={author}
          position={position}
          sx={{
            textAlign: ['center', 'center', 'left', 'center', 'left'],
            mb: [null, null, 256, 0, 254],
          }}
        />
      </GridItem>
      {/* Line decoration */}
      <GridItem
        sx={{
          display: ['block', 'block', 'none', 'block', 'none', 'none'],
          gridColumnStart: [1, 1, null, 1, null, null],
          mx: ['auto', 'auto', 0, 'auto', 0, 0],
          mb: [200, 85, 0, 160],
        }}
      >
        <Line sx={{ height: [80, 64, 0, 40, 0, 0] }} />
      </GridItem>
      {/* Grey Background */}
      <GridItem
        className='grey-backgrund'
        sx={{
          variant: 'measure.gridColumnFallback',
          bg: 'gray50',
          gridRow: [4, 4, 4],
          gridColumn: [null, '4/12', '8/25', '11/23', '3/25', '5/25'],
          height: [null, null, '423px', null, '673px', '673px'],
          zIndex: -3,
          margin: [
            null,
            '-40px 0 0',
            '-90px 0 -65px 0',
            '-77px 0 -49px',
            '-114px 0 0',
          ],

          '@media screen and (max-width: 500px)': {
            variant: 'measure.fullWidth',
            gridColumnStart: 4,
            margin: '-64px 0 -40px',
          },
        }}
      />
      {/* Red Stripe */}
      <GridItem
        sx={{
          variant: 'measure.gridColumnFallback',
          gridRowStart: [4],
          gridColumn: [
            null,
            '3 / span 10',
            '1 / span 16',
            '1 / span 14',
            '1 / span 11',
            '2 / span 9',
          ],
          mt: [null, 416, -53, -31, 168, 168],
          zIndex: -2,
          overflow: 'hidden',

          '@media screen and (max-width: 500px)': {
            variant: 'measure.fullWidth',
            mt: 46,
          },
        }}
      >
        <Image src={stripeSrc} />
      </GridItem>
      {/* Media Card */}
      <MediaCard
        cardTitle={cardTitle}
        cardText={cardText}
        cardImage={cardImage.childImageSharp.gatsbyImageData}
        buttonText={buttonText}
        sx={{
          gridRowStart: [4],
          gridColumn: [
            '1 / span 12',
            '2 / span 9',
            '2 / span 22',
            '3 / span 22',
            '2 / span 9',
            '3 / span 7',
          ],
          display: ['block', 'block', 'flex', 'flex', 'block'],
          // mt: [200, 85, 256, 160, 254],
          mb: 'auto',
        }}
      />
      {/* Empowering small businesses part */}
      <GridItem
        sx={{
          mt: [122, 122, 122, 122, 'auto'],
          gridColumn: [
            null,
            '1 / span 12',
            '4 / span 18',
            '4 / span 18',
            '14 / span 11',
          ],
          gridRowStart: [6, 6, 6, 6, 4],
          mb: [null, null, null, null, 'auto'],
        }}
      >
        <GridItem
          sx={{
            variant: 'measure.gridColumnFallback',
            position: 'relative',

            '@media screen and (max-width: 500px)': {
              variant: 'measure.fullWidth',
            },
          }}
        >
          <Image
            src={circleSrc}
            sx={{
              transform: [
                'translate(-17%, 0)',
                'translate(0, 0)',
                'translate(0, 0)',
                'translate(0, 0)',
                'translate(0, 0)',
              ],
              width: [null, '100%'],
            }}
          />
        </GridItem>
        <Heading
          type='h3'
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: [
              'translate(-50%, -50%)',
              'translate(-50%, -50%)',
              'translate(-50%, -50%)',
              'translate(-50%, -50%)',
              'translate(-30%, -50%)',
              'translate(-50%, -50%)',
            ],
            textAlign: 'center',
          }}
        >
          {headline}
        </Heading>
      </GridItem>
    </Section>
  );
});
