import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
// pieces import
import { Box, Card, Paragraph, Heading } from 'pieces';
// component import
import { SecondaryButton } from 'components/buttons';

export const MediaCard = ({
  cardTitle,
  cardText,
  cardImage,
  buttonText,
  children,
  sx,
  ...props
}) => (
  <Card
    variant='mediaCard'
    sx={{
      ...sx,
    }}
    {...props}
  >
    <Box
      sx={{ position: 'relative', width: '100%', mr: [null, null, 24, 24, 0] }}
    >
      <GatsbyImage image={cardImage} />
      <SecondaryButton
        text={buttonText}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </Box>
    <Box
      sx={{
        margin: ['1.5em 0 0', '1.5em 0 0', 'auto 0', 'auto 0', '1.5em 0 0'],
      }}
    >
      <Heading as='h4' type='cardTitle'>
        {cardTitle}
      </Heading>
      <Paragraph type='cardParagraph' sx={{ mt: '0.25em' }}>
        {cardText}
      </Paragraph>
    </Box>
    {children}
  </Card>
);

MediaCard.propTypes = {
  sx: PropTypes.shape(),
  cardTitle: PropTypes.string.isRequired,
  cardText: PropTypes.string.isRequired,
  cardImage: PropTypes.shape().isRequired,
  buttonText: PropTypes.string.isRequired,
  children: PropTypes.node,
};

MediaCard.defaultProps = {
  sx: {},
  children: null,
};
