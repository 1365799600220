/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line no-unused-vars
import React from 'react';
// import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
// pieces import
import { Card, Heading, FlexWrapper, GatsbyImage } from 'pieces';

export const AwardCard = ({ awardName, awardLogo, sx, ...props }) => (
  <>
    <Card variant='awardCard' sx={{ ...sx }} {...props}>
      <FlexWrapper sx={{ alignItems: 'center' }}>
        <GatsbyImage
          image={awardLogo}
          sx={{
            // temporary fix
            width: ['64px', '80px', '80px', '80px', '80px', '64px'],
            height: ['64px', '80px', '80px', '80px', '80px', '64px'],
            mr: '1em',
          }}
        />
        <Heading as='h6' type='awardTitle'>
          {awardName}
        </Heading>
      </FlexWrapper>
    </Card>
  </>
);

AwardCard.propTypes = {
  sx: PropTypes.shape(),
  awardName: PropTypes.string.isRequired,
  awardLogo: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
};

AwardCard.defaultProps = {
  sx: {},
};
