// external dependencies
import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// pieces components
import {
  Section,
  InlineGridWrapper,
  Heading,
  Paragraph,
  GridItem,
  GatsbyImage,
  Image,
  Card,
} from 'pieces';

import yearIllustration from 'images/year-mdl.svg';

const defaultGridColumnEnd = [
  'span 12',
  'span 12',
  'span 18',
  'span 18',
  'span 11',
  'span 11',
];

export const Header = memo(() => {
  const {
    landingPageJson: {
      header: { headline, subheadline, text, image, logos },
    },
    checkmark: { publicURL: checkmarkSrc },
    info: { publicURL: infoSrc },
  } = useStaticQuery(graphql`
    {
      landingPageJson {
        header {
          headline
          subheadline
          text
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            }
          }
          logos {
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            alt
          }
        }
      }
      checkmark: file(relativePath: { eq: "checkmark.svg" }) {
        publicURL
      }
      info: file(relativePath: { eq: "info.svg" }) {
        publicURL
      }
    }
  `);

  const arrCards = [
    '1 Haupsteite',
    'Impressum & Datenschutz',
    '1x Modul',
    'bis zu 3 Inhaltsabschnitte',
    'Keine Mindestlaufzeit',
    'Monatlich kündbar',
  ];

  return (
    <Section
      id='header'
      sx={{ mt: ['3em', '3em', '3em', '3em', '3em', '5em'] }}
    >
      {/* Quick implementation (temp) */}
      <Card
        sx={{
          p: '24px 24px 65px',
          bg: '#F6F5EF',
          gridColumn: '1/span 12',
          boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.15)',
        }}
      >
        <Heading>Visitenkarte.</Heading>
        <Paragraph type='cardTemp' sx={{ mb: 11 }}>
          Zusätzlich zu unseren Leistungen:
        </Paragraph>
        {arrCards.map((item, index) => {
          if (index === 2 || index === 3) {
            return (
              <Paragraph type='cardTemp' sx={{ mb: 16 }}>
                <Image src={checkmarkSrc} sx={{ mr: 14 }} /> {item}
                <Image src={infoSrc} sx={{ float: 'right' }} />
              </Paragraph>
            );
          }

          return (
            <Paragraph type='cardTemp' sx={{ mb: 16 }}>
              <Image src={checkmarkSrc} sx={{ mr: 14 }} /> {item}
            </Paragraph>
          );
        })}

        <Heading type='h3' as='h4' sx={{ mt: 48 }}>
          29,00 € / Monat
        </Heading>
        <Paragraph type='cardTemp' sx={{ mt: 4 }}>
          bei jährlicher Vorauszahlung
        </Paragraph>
        <Heading type='h3' as='h4' sx={{ mt: 30 }}>
          35,00 € / Monat
        </Heading>
        <Paragraph type='cardTemp' sx={{ mt: 4 }}>
          bei monatlicher Zahlung
        </Paragraph>
      </Card>
      {/* Quick implementation (temp) */}

      <InlineGridWrapper
        sx={{
          gridColumnStart: [1, 1, 1, 1, 1, 1],
          gridColumnEnd: defaultGridColumnEnd,
          gridRow: 1,
        }}
      >
        <Heading
          type='h4'
          sx={{
            gridColumnStart: [
              'span 8',
              'span 12',
              'span 18',
              'span 18',
              'span 11',
              'span 11',
            ],
          }}
        >
          {subheadline}
        </Heading>
        <Heading sx={{ gridColumnStart: defaultGridColumnEnd }} type='h1'>
          {headline}
        </Heading>
        <Paragraph
          type='lead'
          sx={{
            gridColumnEnd: [
              'span 12',
              'span 12',
              'span 18',
              'span 18',
              'span 11',
              'span 9',
            ],
          }}
        >
          {text}
        </Paragraph>
      </InlineGridWrapper>
      {/* Header Image */}
      <GridItem
        sx={{
          boxShadow: 'image',
          marginTop: [80, 80, 80, 48, 115, 0],
          gridColumn: ['1/13', '1/13', '10/25', '10/25', '14/25', '14/25'],
          gridRow: [null, null, 2, 2, '1/3'],
        }}
      >
        <GatsbyImage image={image} />
      </GridItem>
      {/* Year Illustration */}
      <GridItem
        sx={{
          gridColumn: ['3/11', '4/10', '5/15', '5/15', '16/23', '12/18'],
          marginTop: ['-80px', '-100px', 'auto', 'auto', '-100px', '-110px'],
          paddingBottom: '100px',
          gridRow: [null, null, 2, 2, 3, 3],
        }}
      >
        <Image
          src={yearIllustration}
          sx={{ width: '100%', mixBlendMode: 'multiply' }}
        />
      </GridItem>
      {/* Logos */}
      <GridItem
        sx={{
          display: 'flex',
          flexWrap: ['wrap-reverse', 'wrap-reverse', 'no-wrap', 'no-wrap'],
          gridColumn: ['3/11', '4/10', '10/25', '10/25', '14/25', '19/25'],
          marginTop: ['29px', '24px', '25px', '25px', '-60px', '24px'],
          alignItems: 'flex-end',
        }}
      >
        {logos.map(({ image: logo, alt }) => (
          <GatsbyImage
            sx={{
              height: ['35px', '38px', '40px', '40px', '40px'],
              width: ['45%', '45%', 'auto', 'auto'],
              flexGrow: 1,
            }}
            image={logo}
            alt={alt}
          />
        ))}
      </GridItem>
    </Section>
  );
});
