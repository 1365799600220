// external dependencies
import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
// pieces components
import {
  Section,
  Heading,
  GridItem,
  Image,
  Box,
  InlineGridWrapper,
  Paragraph,
  FlexWrapper,
} from 'pieces';
// component imports
import { AwardCard } from 'components/cards';
import { PrimaryButton } from 'components/buttons';

export const Awards = memo(() => {
  const {
    landingPageJson: {
      awards: { subheadline, headline, text, button, awards: awardData },
    },
    handIllustration: { publicURL: handSrc },
    crown: { publicURL: crownSrc },
    arrow: { publicURL: arrowSrc },
  } = useStaticQuery(graphql`
    {
      landingPageJson {
        awards {
          subheadline
          headline
          text
          button
          awards {
            name
            logo {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      handIllustration: file(
        relativePath: { eq: "hand-awards-illustration.svg" }
      ) {
        publicURL
      }
      crown: file(relativePath: { eq: "crown.svg" }) {
        publicURL
      }
      arrow: file(relativePath: { eq: "icons/arrow.svg" }) {
        publicURL
      }
    }
  `);

  const award1 = awardData[0];
  const award2 = awardData[1];
  const award3 = awardData[2];

  const image = getImage(award1.logo.childImageSharp.gatsbyImageData);

  const AwardCardSpan = [
    'span 9',
    'span 7',
    'span 11',
    'span 11',
    'span 8',
    'span 6',
  ];

  return (
    <Section id='awards'>
      {/* Gray Background */}
      {console.log(image)}
      <GridItem
        sx={{
          margin: 0,
          bg: 'gray50',
          gridRowStart: '1',
          // saying gridRowEnd: 4 means it will span row 1,2 and 3
          gridRowEnd: '4',
          gridColumnStart: [null, null, null, null, null, 4],
          gridColumnEnd: [null, null, null, null, null, 'span 21'],
          variant: 'measure.gridColumnFallback',

          '@media screen and (max-width: 1400px)': {
            variant: 'measure.fullWidth',
            margin: ['40px 0 0', '66px 0 0', '73px 0 0', '38px 0 0', '0 0 0'],
          },
        }}
      />
      {/* Blue Background */}
      <Box
        sx={{
          position: 'relative',
          height: [241, 342, 348, 256, 370, 362],
          gridColumnStart: [3, 4, 10, 14, 2, 2],
          gridColumnEnd: [
            'span 9',
            'span 8',
            'span 12',
            'span 10',
            'span 9',
            'span 7',
          ],
          gridRowStart: [1],
          background:
            'linear-gradient(48.17deg, #1F3942 -1.1%, #111F27 99.62%)',
          mt: ['89px', '189px', '187px', '119px', '138px', '189px'],
        }}
      >
        {/* Red crown */}
        <Image
          src={crownSrc}
          sx={{
            position: 'absolute',
            width: ['111px', '131px'],
            bottom: [-46, -74, -34, -39, -30, -54],
            right: [-52, -69, -75, -59, -72, -65],
            mixBlendMode: 'multiply',
          }}
        />
      </Box>
      {/* Hand illustration */}
      <GridItem
        sx={{
          gridColumnStart: [3, 4, 11, 15, 3, 3],
          gridColumnEnd: [
            'span 10',
            'span 9',
            'span 14',
            'span 10',
            'span 9',
            'span 8',
          ],
          gridRowStart: [1],
        }}
      >
        <Image
          src={handSrc}
          sx={{
            // width: [265, 386, 408, 282, 370, 415],
            height: [398, 577, 610, 423, 556, 620],
          }}
        />
      </GridItem>
      {/* Award Card */}
      <InlineGridWrapper
        sx={{
          gridColumnEnd: [
            'span 12',
            'span 9',
            'span 14',
            'span 15',
            'span 11',
            'span 9',
          ],
          gridColumnStart: [1, 2, 1, 1, 1, 1],
          gridRowStart: [2],
          mt: [-188, -215, -429, -359, -233, -241],
          // temporary
          mb: [114, 120, 121, 115, 49, 116],
        }}
      >
        <AwardCard
          awardName={award1.name}
          awardLogo={award1.logo}
          sx={{
            mb: ['auto'],
            gridColumnEnd: AwardCardSpan,
            gridColumnStart: [2, 2, 3, 4, 4, 4],
          }}
        />
        <AwardCard
          awardName={award2.name}
          awardLogo={award2.logo}
          sx={{
            mt: [16, 16, 16, 16, 24, 24],
            mb: ['auto'],
            gridColumnEnd: AwardCardSpan,
            gridColumnStart: [1, 1, 1, 1, 1, 1],
          }}
        />
        <AwardCard
          awardName={award3.name}
          awardLogo={award3.logo}
          sx={{
            mt: [32, 34, 32, 32, 48, 56],
            mb: ['auto'],
            gridColumnEnd: AwardCardSpan,
            gridColumnStart: [4, 3, 5, 5, 3, 3],
          }}
        />
      </InlineGridWrapper>
      {/* Text Block */}
      <GridItem
        sx={{
          gridColumnStart: [1, 1, 1, 1, 14, 13],
          gridColumnEnd: [
            'span 12',
            'span 10',
            'span 19',
            'span 21',
            'span 11',
            'span 9',
          ],
          // Something is still weird with the positon of the text block on breakpoint 5
          // Not it looks good again! All good - it just needed to span the first two rows
          gridRow: [3, 3, 3, 3, '1/3', '1/3'],
          margin: [null, null, null, null, 'auto 0', 'auto 0'],
        }}
      >
        {/* I think in the header its type h4 isn't it? I would do the same here */}
        <Heading type='h4' as='h4'>
          {subheadline}
        </Heading>
        <Heading type='h3' as='h3' sx={{ mt: 8, mb: [16, 16, 16, 16, 16, 24] }}>
          {headline}
        </Heading>
        <Paragraph type='paragraph'>{text}</Paragraph>
        <FlexWrapper
          sx={{
            alignItems: 'center',
            mt: [64, 64, 64, 68, 64, 80],
            mb: [48, 65, 58, 60, 0, 0],
          }}
        >
          <PrimaryButton text={button} />
          <Image
            src={arrowSrc}
            sx={{
              ml: [24, 22, 24, 12, 32, 32],
              height: [22, 22, 24, 23, 24, 26],
            }}
          />
        </FlexWrapper>
      </GridItem>
    </Section>
  );
});
