import React from 'react';
// Pieces imports
import { SEO, Divider } from 'pieces';
// sections
import {
  Header,
  Quote,
  Awards,
  Categories,
  Team,
  Contact,
} from 'sections/landingPage';

const IndexPage = () => (
  <>
    <SEO title='Home' />
    <Divider sx={{ height: ['5vw'] }} />
    <Header />
    <Divider
      sx={{ height: ['80px', '112px', '196px', '120px', '120px', '30px'] }}
    />
    <Quote />
    <Divider
      sx={{ height: ['117px', '36px', '165px', '111px', '213px', '192px'] }}
    />
    <Awards />
    <Divider
      sx={{ height: ['180px', '200px', '235px', '160px', '200px', '254px'] }}
    />
    <Categories />
    <Divider
      sx={{ height: ['146px', '121px', '203px', '144px', '203px', '164px'] }}
    />
    <Team />
    <Divider
      sx={{ height: ['104px', '281px', '137px', '113px', '72px', '0px'] }}
    />
    <Contact />
    <Divider sx={{ height: ['200px'] }} />
  </>
);

export default IndexPage;
