import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useMotionValue } from 'framer-motion';
import PropTypes from 'prop-types';
// piece imports
import { FlexWrapper, FlexItem, Image, Heading, Paragraph, Box } from 'pieces';
import { PrimaryButton } from 'components/buttons';

export const RenovationCard = ({
  renovationTitle,
  renovationText,
  buttonText,
  sx,
  ...props
}) => {
  const ref = React.useRef();
  const underlineWidth = useMotionValue(20);

  const {
    exclamation: { publicURL: exclamationSrc },
    underline: { publicURL: underlineSrc },
  } = useStaticQuery(graphql`
    query {
      exclamation: file(relativePath: { eq: "exclamation.svg" }) {
        publicURL
      }
      underline: file(relativePath: { eq: "underline.svg" }) {
        publicURL
      }
    }
  `);

  React.useEffect(() => {
    if (ref.current) {
      underlineWidth.set(ref.current.offsetWidth);
    }
  }, []);

  return (
    <FlexWrapper
      sx={{ flexDirection: ['column', 'column', 'row', 'row'], ...sx }}
      {...props}
    >
      <FlexItem sx={{ mr: [0, 0, 49, 39, 43, 32] }}>
        <Image
          src={exclamationSrc}
          sx={{
            width: [24, 24, 41, 44, 41, 41],
          }}
        />
      </FlexItem>
      <FlexWrapper sx={{ flexDirection: 'column' }}>
        <FlexItem
          sx={{
            mt: [40, 40, 0, 0, 0, 0],
            mb: [58, 58, 0, 0, 0, 0],
          }}
        >
          <Heading type='h3'>{renovationTitle}</Heading>
          <Paragraph sx={{ pt: [8] }} type='card'>
            {renovationText}
          </Paragraph>
        </FlexItem>
        <Box
          sx={{ display: 'block', mx: ['auto', 'auto', '0', '0', '0', '0'] }}
        >
          <PrimaryButton
            ref={ref}
            text={buttonText}
            sx={{ pt: [0, 0, 40, 40, 40, 32] }}
          />
          <Image
            sx={{ pt: [16] }}
            style={{ width: underlineWidth }}
            src={underlineSrc}
          />
        </Box>
      </FlexWrapper>
    </FlexWrapper>
  );
};

RenovationCard.propTypes = {
  renovationTitle: PropTypes.string.isRequired,
  renovationText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  sx: PropTypes.shape(),
};

RenovationCard.defaultProps = {
  sx: {},
};
