import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Paragraph } from 'pieces';

export const QuoteBlock = ({ quote, author, position, sx, ...props }) => (
  <Box
    sx={{
      textAlign: ['center', 'center', 'center', 'center', 'left', 'left'],
      ...sx,
    }}
    {...props}
  >
    <Heading as='quote' type='quote'>
      {quote}
    </Heading>
    <Paragraph
      type='quoteAuthor'
      as='p'
      sx={{
        paddingTop: [16, 16],
      }}
    >
      {author} | {position}
    </Paragraph>
  </Box>
);

QuoteBlock.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  sx: PropTypes.shape(),
};

QuoteBlock.defaultProps = {
  sx: {},
};
