// external dependencies
import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// pieces components
import {
  Section,
  Heading,
  Image,
  GridItem,
  InlineGridWrapper,
  Paragraph,
} from 'pieces';
// components import
import { CategoryCard, RenovationCard } from 'components/cards';

const defaultGridColumnEnd = [
  'span 12',
  'span 12',
  'span 19',
  'span 20',
  'span 11',
  'span 10',
];

const cardSpan = [
  'span 12',
  'span 9',
  'span 22',
  'span 22',
  'span 9',
  'span 7',
];

export const Categories = memo(() => {
  const {
    landingPageJson: {
      categories: {
        headline,
        subheadline,
        text,
        buttonText,
        categories,
        renovationTitle,
        renovationText,
      },
    },
    circle: { publicURL: circleSrc },
    head: { publicURL: headSrc },
  } = useStaticQuery(graphql`
    query {
      landingPageJson {
        categories {
          headline
          subheadline
          text
          buttonText
          categories {
            title
            text
            images {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
              }
            }
          }
          renovationTitle
          renovationText
        }
      }
      circle: file(relativePath: { eq: "sribble-circle.svg" }) {
        publicURL
      }
      head: file(relativePath: { eq: "face.svg" }) {
        publicURL
      }
    }
  `);

  const card1 = categories[0];
  const card2 = categories[1];
  const card3 = categories[2];

  return (
    <Section id='categories' sx={{ overflow: 'hidden' }}>
      {/* Text Block Emphasized */}
      <InlineGridWrapper
        sx={{
          gridColumnStart: [1, 1, 5, 1, 1, 2],
          gridColumnEnd: defaultGridColumnEnd,
          marginBottom: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          gridRow: 1,
          mb: '128px',
        }}
      >
        {/* Sub Headline */}
        <Heading
          type='h4'
          sx={{
            gridColumnStart: [
              'span 12',
              'span 11',
              'span 18',
              'span 16',
              'span 11',
              'span 10',
            ],
          }}
        >
          {subheadline}
        </Heading>
        <Heading
          sx={{
            gridColumnEnd: [
              'span 12',
              'span 12',
              'span 20',
              'span 19',
              'span 11',
              'span 10',
            ],
          }}
          type='h2'
        >
          {headline}
        </Heading>
        <Paragraph
          sx={{
            gridColumnEnd: [
              'span 12',
              'span 12',
              'span 20',
              'span 19',
              'span 10',
              'span 8',
            ],
          }}
        >
          {text}
        </Paragraph>
      </InlineGridWrapper>
      {/* Cards */}
      <CategoryCard
        cardTitle={card1.title}
        cardParagraph={card1.text}
        imgSrc={card1.images.childImageSharp.gatsbyImageData}
        buttonText={buttonText}
        sx={{
          gridColumnEnd: cardSpan,
          gridColumnStart: [1, 1, 1, 1, 16, 15],
          mt: ['109px', '88px', '98px', '80px', '170px', '201px'],
          mb: ['48px', '35px', 'auto', 'auto', '0', '0'],
          gridRow: ['auto', 2, 2, 2, 1, 1],
        }}
      />
      <CategoryCard
        cardTitle={card2.title}
        cardParagraph={card2.text}
        imgSrc={card2.images.childImageSharp.gatsbyImageData}
        buttonText={buttonText}
        sx={{
          gridColumnEnd: cardSpan,
          gridColumnStart: [1, 4, 3, 3, 4, 7],
          mt: ['0', '70px', '75px', '46px', '-97px', '-71px'],
          mb: ['48px', '35px', 'auto', 'auto', 'auto', 'auto'],
          gridRow: ['auto', 3, 3, 3, 2, 2],
        }}
      />
      <CategoryCard
        cardTitle={card3.title}
        cardParagraph={card3.text}
        imgSrc={card3.images.childImageSharp.gatsbyImageData}
        buttonText={buttonText}
        sx={{
          gridColumnEnd: cardSpan,
          gridColumnStart: [1, 2, 2, 2, 15, 17],
          mt: ['0', '70px', '75px', '46px', '186px', '130px'],
          mb: [null, null, 'auto', 'auto', 'auto', 'auto'],
          gridRow: ['auto', 4, 4, 4, 2, 2],
        }}
      />
      {/* Head Illustration */}
      <Image
        sx={{
          display: ['none', 'none', 'none', 'none', 'block', 'block'],
          gridRow: ['auto', 'auto', 'auto', 'auto', '2', '2'],
          gridColumn: [null, null, null, null, '10 / span 5', '15 / span 4'],
          zIndex: '-1',
          mt: [null, '0', '0', '0', '-70px', '0'],
          ml: [null, null, null, null, '100px', '-70px'],
          width: '100%',
        }}
        src={headSrc}
      />
      {/* Full Width GridItem, to position the red circle */}
      <GridItem
        sx={{
          variant: 'measure.fullWidth',
          display: ['none', 'none', 'block', 'block', 'none', 'none'],
          gridRow: ['auto', 'auto', '2/5', '2/5', 'auto', 'auto'],
          zIndex: '-1',
          transform: [null, null, 'rotate(-180deg)', 'rotate(-180deg)'],
        }}
      >
        {/* Red Circle in Background */}
        <Image
          sx={{
            mt: [null, '250px', '175px', '193px', '0', '0'],
            ml: [null, '100px', '200px', '250px'],
            height: [null, '68%', '588px', '500px', '0', '0'],
            width: '100%',
          }}
          src={circleSrc}
        />
      </GridItem>
      <RenovationCard
        sx={{
          gridColumnEnd: [
            'span 12',
            'span 10',
            'span 21',
            'span 17',
            'span 13',
            'span 10',
          ],
          gridColumnStart: [1, 2, 1, 8, 1, 2],
          textAlign: ['center', 'center', 'left', 'left'],
          mt: ['105px', '160px', '101px', '111px', '179px', '-84px'],
        }}
        renovationTitle={renovationTitle}
        renovationText={renovationText}
        buttonText={buttonText}
      />

      {/* For testing */}
      {/* <GridItem>
        <SecondaryButton text={buttonText} />
      </GridItem> */}
    </Section>
  );
});
